import React from "react"

const BlueBox = ({ data, children }) => { 

    //The image centering below only works with external images
    //otherwise gatsby-img gets invovled and puts a white box-shadow
    //beneath the images, which for transparent PNGs on a blue
    //background... doesn't work well

    return(
        <div style={{
            background: '#4c96c6',
            color: '#fff',
            width: '95%',
            position: 'relative',
            padding: '1em',
            margin: '2em auto',
            borderRadius: '10px'}}>
                {children.map(element => {
                        //make sure any images are centered  
                        if(element.props.mdxType === 'img'){
                            return(
                                <img key={element.props.src}
                                    style={{
                                    display: "block", 
                                    marginLeft: "auto", 
                                    marginRight: "auto", 
                                    marginBottom: "1rem"}}
                                    src={element.props.src}
                                    alt={element.props.alt}
                                     />                    
                            )
                        } else {
                            return(element)
                        }
                    })}
        </div>
    )

}

export default BlueBox