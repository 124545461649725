import React from 'react'
import { graphql, Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../scss/custom.scss'
import Header from '../components/Header'
import Caption from '../components/Caption'
import BlueBox from '../components/BlueBox'
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import SiteMetadata from '../components/SiteMetadata'

const shortcodes = {Caption, BlueBox,
    blockquote: props => <blockquote {...props} style={{color:'grey', margin:'0rem 1.5rem 0rem 1.5rem', lineHeight:'1.625'}} />}

export default function PageTemplate( { data, pageContext } ) {
    
    const {next, prev} = pageContext
    const { mdx } = data
    const title = mdx.frontmatter.title
    const timeToRead = mdx.timeToRead
    const relativeDate = mdx.frontmatter.date
    var nextLink = ''
    var prevLink = ''

    //need to do the check to see if prev or next exist so we don't render them if they're null
    if(prev){
        prevLink = 
            <Link to={prev.fields.slug} style={{color:'grey'}}>
                <Button variant='light' style={{color:'grey', maxWidth:'200px'}}>
                    <div>← Previous post </div>  
                        {prev.frontmatter.title}
                </Button>
            </Link>
    }
    
    if (next){
        nextLink = 
            <Link to={next.fields.slug} style={{color:'grey'}}>
                <Button variant='light' style={{color:'grey', maxWidth:'200px'}}>
                    <div>Next post →</div>
                        {next.frontmatter.title}  
                </Button>
            </Link>
    }

    return (
          
        <div>
            <SiteMetadata pathname={ pageContext.pathSlug } pagetitle = {title} />
            <div id="navigation" style={{marginBottom:'64px'}}>
                <Header />
            </div>

            <div id='middle-column' className='container-md'>
                <div>
                    <h1 style={{fontFamily:"Georgia", marginBottom:"8px"}}>{title}</h1>
                </div>
                <p><small className="text-muted">{relativeDate} · {timeToRead} min read</small></p>
                <div className='blogpost'>
                    <MDXProvider components={shortcodes}>
                        <MDXRenderer>{mdx.body}</MDXRenderer>
                    </MDXProvider>
                </div>
            </div>        
            <div id='footer' className='container-md' style={{marginTop: '128px', marginBottom:'256px', color:'grey'}}>
                <hr style={{marginBottom:'64px'}}/>
                <Container>
                    <Row>
                        <Col>
                            {prevLink}
                        </Col>
                        {/* <Col></Col> */}
                        <Col style={{textAlign:'right'}}>
                            {nextLink}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div> 
    )
}

export const pageQuery = graphql`
    query($pathSlug:String!){
        mdx(fields: { slug: { eq: $pathSlug} }){
            id
            body
            frontmatter {
                title
                date (fromNow: true)
            }
            timeToRead
        }
    }
`